<template>
  <div class="my-container mb-40 md:mb-0">
    <h2 class="my-title-2 text-center md:text-left mb-4">
      {{ $t("terms.title") }}
    </h2>

    <p class="mb-4">{{ $t("terms.parragraph_0") }}</p>
    <p class="font-semibold">{{ $t("terms.parragraph_1") }}</p>
    <p class="mb-4">{{ $t("terms.parragraph_1_1") }}</p>
    <p class="font-semibold">{{ $t("terms.parragraph_2") }}</p>
    <p class="mb-4">{{ $t("terms.parragraph_2_1") }}</p>
    <p class="font-semibold">{{ $t("terms.parragraph_3") }}</p>
    <p class="mb-4">{{ $t("terms.parragraph_3_1") }}</p>
    <p class="font-semibold">{{ $t("terms.parragraph_4") }}</p>
    <p class="mb-4">{{ $t("terms.parragraph_4_1") }}</p>
    <p class="font-semibold">{{ $t("terms.parragraph_5") }}</p>
    <p class="mb-4">{{ $t("terms.parragraph_5_1") }}</p>
    <p class="font-semibold">{{ $t("terms.parragraph_6") }}</p>
    <p class="mb-4">{{ $t("terms.parragraph_6_1") }}</p>
  </div>
</template>

<script>
export default {
  metaInfo: { title: "Términos y condiciones" },
};
</script>

<style></style>
